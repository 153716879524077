<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div  class="rn-column-area rn-section-gap mt--150">
      <v-container>
            <center><h3 class="mb--100"><b>Τα δώρα μου</b></h3></center>
            
            <div v-if="userGifts.user_gifts.length == 0">
              Δεν υπάρχουν δεδομένα
            </div>
            <div v-else>
                <v-row id="userGiftsList" v-for="(column, i) in userGifts.user_gifts"
                            :key="i" class="mb--100 blog_details">
                    <v-col lg="2"
                          md="2"
                          sm="6"
                          cols="12">
                    </v-col>
                    <v-col lg="2"
                          md="2"
                          sm="12"
                          cols="12">
                        <div class="single-column">
                            <img width="150" :src="`${column.gift.image}`" />
                        </div>
                    </v-col>
                    <v-col lg="2"
                          md="2"
                          sm="6"
                          cols="12">
                        
                    </v-col>
                    <v-col lg="5"
                          md="5"
                          sm="12"
                          cols="12">
                        <div class="single-column mt--15" >
                            <h3> {{ column.gift.title }}</h3>
                            <div v-if="column.promo_type.promo_type_id === 9">
                              <p style="color: #008000" v-if="column.is_used">Εξαργυρωμένο</p>
                            </div>
                            <p>{{ column.gift.description }}</p>
                            <div v-if="column.promo_type.promo_type_id === 9 && column.status !== 'Expired'">
                              <p v-if="!column.is_used">Κωδικός εξαργύρωσης {{ column.voucher }}</p>
                            </div>
                            <div v-else-if="column.status === 'Expired'">
                              <p style="color: #FF0033">Εληξε</p>
                            </div>
                        </div>
                    </v-col>

                    <v-col lg="1"
                          md="1"
                          sm="6"
                          cols="12">
                        
                    </v-col>
                </v-row>
                 <b-pagination
                    class="mt--100"
                    v-if="this.userGifts.pagination.total_pages > 1"
                    v-model="currentPage"
                    v-on:click.native="scrollToTop"
                    :per-page="this.userGifts.pagination.items_per_page"
                    :total-rows="this.userGifts.pagination.total"
                    aria-controls="userGiftsList"
                    align="center"
                  ></b-pagination> 
                </div>
      </v-container>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import Gifts from "@/components/gifts/Gifts";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";

  export default {
    components: {
      Header,
      Footer,
      Gifts,
    },
    data() {
      return {        
        currentPage: 1,
        loading: true,
      };
    },
    watch: {
      currentPage: async function (newQuestion, oldQuestion) {
        await this.getUserGifts(newQuestion);
      }
    },
    computed: {
       ...mapGetters (["userGifts"]),
       ...mapGetters (["user"]),
    },
    async  created () {
      if(!this.user){
        this.$loading(true);
        await this.getUser();
        this.$loading(false);
        if(!this.user){
         this.$router.push('/')
         return
        }
      }
      if(!this.userGifts){
        this.$loading(true);
        await this.getUserGifts(1);
        this.$loading(false);
      }else{
        await this.getUserGifts(1);
      }
    },
    methods: {
      ...mapActions(["getUserGifts"]),
      ...mapActions(["getUser"]),
      scrollToTop() {
        this.$vuetify.goTo(0);
      }
    },
  };
</script>
<style lang="scss" scoped>
.blog_details {
    padding: 30px 0 20px 10px;
    box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
}
</style>